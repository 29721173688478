import React, { useRef, useEffect } from 'react';
import Cookies from 'js-cookie';

import { BaseMarkup } from '../reactify/src/components/BaseMarkup';
import { usePromise } from '../reactify/src/components/usePromise';
import { apiFetch } from '../reactify/src/utils/apiFetch';
import { APP_URL } from '../reactify/src/config';

import snapprLogoSrc from '../reactify/src/components/img/snappr-logo.svg';

function storeLoginCookie({ token }: { token: string }) {
  Cookies.set('snappr.admin.auth', token, {
    path: '/',
    // Expires in a year
    expires: 365,
    sameSite: 'lax',
    domain: '.snappr.com',
  });
}

export default function Index() {
  const emailRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [totpCode, setTotpCode] = React.useState<string>('');

  const [authState, setAuthState] = React.useState<
    'LOGIN' | 'SETUP_TOTP' | 'ENTER_TOTP_CODE'
  >('LOGIN');

  const [totpQrImage, setTotpQrImage] = React.useState<string>();

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const {
    setPromise: setLoginPromise,
    isPending: loginPending,
    error,
  } = usePromise();

  return (
    <BaseMarkup>
      <div className="container d-flex flex-column justify-content-center flex-grow-1 my-5">
        <div className="d-flex justify-content-center">
          <img
            src={snapprLogoSrc}
            alt="snappr logo"
            style={{
              maxWidth: 300,
              width: '100%',
            }}
          />
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-12 col-lg-6">
            {!loginPending && error != null && (
              <div className="alert alert-danger" role="alert">
                Login failed.
              </div>
            )}

            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={(ev) => {
                    ev.preventDefault();

                    setLoginPromise(
                      apiFetch('/auth/token', {
                        method: 'POST',
                        body: JSON.stringify({
                          username: email,
                          password,
                          totp_code:
                            totpCode != null && totpCode !== ''
                              ? totpCode
                              : undefined,
                        }),
                      }).then(
                        ({
                          expire,
                          token,
                          uid,
                          totp_qr_image,
                          require_totp_code,
                        }) => {
                          if (require_totp_code === true) {
                            setAuthState('ENTER_TOTP_CODE');
                          } else if (totp_qr_image != null) {
                            setTotpQrImage(totp_qr_image);
                            setAuthState('SETUP_TOTP');
                          } else {
                            const localStorageService = window.localStorage;

                            localStorageService.setItem('_auth.token', token);
                            localStorageService.setItem('_auth.expire', expire);
                            localStorageService.setItem('_auth.uid', uid);

                            storeLoginCookie({ token });
                            window.location.assign('/');
                          }
                        }
                      )
                    );
                  }}
                >
                  {authState === 'SETUP_TOTP' ? (
                    <>
                      <h3 className="text-center mb-3">
                        Set up your authenticator app
                      </h3>

                      <ul>
                        <li>
                          Download the Google Authenticator App on your mobile
                          phone
                        </li>
                        <li>In the App select Set up account</li>
                        <li>Chose Scan barcode</li>
                      </ul>

                      <div className="py-2"></div>

                      <div className="form-group d-flex justify-content-center">
                        <img
                          src={totpQrImage}
                          height={166}
                          width={166}
                          alt={`${email} one time QR code loading...`}
                        />
                      </div>

                      <div className="py-2"></div>

                      <div className="form-group">
                        <label>
                          Enter the 6-digit code you see in the Authenticator
                          App
                        </label>
                        <input
                          onChange={(e) => setTotpCode(e.target.value)}
                          value={totpCode}
                          type="text"
                          className="form-control"
                          disabled={loginPending}
                        />
                      </div>
                    </>
                  ) : authState === 'ENTER_TOTP_CODE' ? (
                    <>
                      <h3 className="text-center mb-3">
                        Two-factor authentication
                      </h3>

                      <p className="text-center">
                        Enter the 6-digit code you see in the Authenticator App
                      </p>

                      <div className="py-2"></div>

                      <div className="form-group">
                        <input
                          onChange={(e) => setTotpCode(e.target.value)}
                          value={totpCode}
                          type="text"
                          className="form-control"
                          disabled={loginPending}
                        />

                        <small className="form-text text-muted mt-3">
                          Lost your two-factor device? Please contact your
                          engineering team and request a reset
                        </small>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <label>
                          <small className="text-secondary">Email</small>
                        </label>

                        <input
                          ref={emailRef}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          type="email"
                          className="form-control"
                          disabled={loginPending}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          <small className="text-secondary">Password</small>
                        </label>

                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          type="password"
                          className="form-control"
                          disabled={loginPending}
                        />
                      </div>
                    </>
                  )}

                  <div className=" justify-content-between">
                    <a
                      href={`${APP_URL}/recover`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-secondary"
                    >
                      Reset your password
                    </a>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loginPending}
                      >
                        {authState === 'ENTER_TOTP_CODE' ? 'Verify' : 'Login'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseMarkup>
  );
}
